<template>
  <div>
    <v-dialog v-model="mostrar" max-width="800" persistent>
      <v-card :loading="loading">
        <v-toolbar color="primary" dark dense flat><h3>Favor de escribir el texto que contendrá el citatorio </h3></v-toolbar>
        <v-container>
         
        
          <v-toolbar v-if="qrActivo" color="warning" dark dense flat>
            <h4>EL QR SE GENERÁ Y SE ADJUNTA AL CORREO EN AUTOMÁTICO</h4>
          </v-toolbar>
          <br>

          <v-row >
            <v-col cols="12">
                <v-text-field 
                  v-model="asunto" 
                  label="Asunto" 
                  :disabled="loading" 
                  outlined 
                  dense 
                  :error-messages="asuntoErrors"
                  @input="$v.asunto.$touch()"
                  @blur="$v.asunto.$touch()">
                </v-text-field>
            </v-col>
          </v-row>
          <v-checkbox v-model="activarLiga" label="Agregar liga"></v-checkbox>
          <vueEditor v-model="contenido" :editor-toolbar="customToolbar" :placeholder="etiqueta"></vueEditor>
          <v-checkbox v-model="qrActivo" label="Agregar QR" @change="qrMetodo()"></v-checkbox>
          <vueEditor v-model="despedida" :editor-toolbar="customToolbar2" placeholder="Escribir despedida"></vueEditor>
          <p v-if="errorContenido" style="color:#b12028">{{ errorContenido }}</p>
        </v-container>
        <v-divider class="mx-3"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" small outlined @click="$emit('cancelar')">Cancelar</v-btn>
          <v-btn color="primary" :disabled="asuntoErrors.length > 0" small @click="verCorreoMetodo()">Previsualizar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
   <vistaPreviaCorreo
      v-if="verCorreo"
      :mostrar="verCorreo"
      :asunto="asunto"
      :texto="contenido"
      :usuarioMaster="usuarioMaster"
      :qr="qrActivo"
      :despedida="despedida"
      :liga="activarLiga"
      @cancelar="verCorreo=false"
      @invitacionGuardada="invitacionGuardada"
   />
  </div>
</template>
  
<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import vistaPreviaCorreo from "./vistaPreviaCorreo.vue" 
import { required } from "vuelidate/lib/validators";
import { qrService } from "./invitaciones.service"
// import { Quill } from "vue2-editor";

// const styleSize = Quill.import('attributors/style/size');
// styleSize.whitelist =  ['18px', '20px', '22px'];
// Quill.register(styleSize, true);
export default {
  name: "formularioInvitacion",
  props: {
      mostrar: Boolean,
      invitacion: Object,
      usuarioMaster:String,
  },
  components: { vistaPreviaCorreo },
  computed: {
    ...mapGetters(["httpURL"]),
    asuntoErrors() {
      const errors = [];
      if (!this.$v.asunto.$dirty) return errors;
      !this.$v.asunto.required && errors.push("Asunto requerido.");
      return errors;
    }
  },
  mixins: [validationMixin],
  validations: {
    asunto: { required },
    //contenido: { required }
  },
  data() {
    return {
      drive:'',
      loading: false,
      idSesion: null,
      contenido: '',
      despedida:'',
      customToolbar: [
        //  [{ 'size': styleSize.whitelist }],
         ['bold', 'italic', 'underline', 'link'],
         [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
         ]
      ],
      customToolbar2: [
        //  [{ 'size': styleSize.whitelist }],
         ['bold', 'italic', 'underline'],
         [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
         ]
      ],
      mostrarCorreo:false,
      etiqueta: 'Escribir el cuerpo del correo',
      asunto:'',
      verCorreo:false,
      errorContenido:null,
      modificarCorreo:false,
      qr:'',
      urlUsuario:'',
      activarLiga:false

    };
  },
  created() {
    console.log(this.invitacion);
    this.idSesion = this.$route.params.idSesion;
    if(this.invitacion){
      this.asunto = this.invitacion.asunto;
      this.contenido = this.invitacion.texto;
      this.despedida=this.invitacion.despedida;
      this.modificarCorreo = true;
      this.qrActivo = this.invitacion.qr
      this.activarLiga=this.invitacion.liga
    }
  },
  methods: {
    async verCorreoMetodo(){
      if(this.contenido == null) return this.errorContenido = "*Cuerpo de correo requerido."
      this.$v.$touch();
      if (!this.$v.$invalid) this.verCorreo= true
    },
    invitacionGuardada(invitacion){
      this.$emit('invitacionGuardada',invitacion)
      this.$emit('cancelar')
    },
    async qrMetodo(){
      try{
        this.loading=true;
        const serverResponse = await qrService(this.idSesion,this.qrActivo)
        this.loading = false;
        if(!serverResponse.ok) throw Error(serverResponse.mensaje)
        else this.qrActivo=serverResponse.qr;
      }
      catch(error){
        this.loading = false;
        this.$notificarError(error);
      }
    },

  },
};
</script>
<style>
/*
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="18px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
  content: "18";
  font-size: 16px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="20px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="20px"]::before {
  content: "20";
  font-size: 16px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="22px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="22px"]::before {
  content: "22";
  font-size: 16px !important;
}
*/
</style>
  
  