<template>
<div>
  <v-main>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <v-card outlined>
            <v-toolbar elevation="1" color="secondary" dark>
              <v-btn icon @click="regresar">
                <v-icon>mdi-arrow-left-bold</v-icon>
              </v-btn>
              <v-toolbar-title class="text-h4">
                {{ sesion.nombre }}</v-toolbar-title
              >
              <v-spacer></v-spacer>
              <sesionMenuComponent
                :sesionAbierta="sesion.abierta"
                @abrirSesion="abrirCerrarSesion = true"
                @cerrarSesion="abrirCerrarSesion = true"
                @modificarSesion="modificarSesion=true"
              />
            </v-toolbar>
            <v-col md="12">
              <v-card flat>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="text-h5">
                      {{ fechaLocal }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-h6">
                      {{ consejo.nombre }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-progress-circular
                      :rotate="-90"
                      :size="50"
                      :width="10"
                      :value="porcentajeAsistencia"
                      color="primary"
                    >
                      {{ asistentes.length }}
                    </v-progress-circular>
                  </v-list-item-action>
                  <v-list-item-action>
                    <v-chip
                      small
                      :outlined="!quorum"
                      :dark="quorum"
                      :color="quorum ? 'green darken-3' : 'red darken-1'"
                      >Quórum - {{ textoQuorum }}</v-chip
                    >
                    
                    <v-chip
                      small
                      :outlined="!votacionEconomica"
                      :dark="votacionEconomica"
                      :color="
                        votacionEconomica ? 'green darken-3' : 'red darken-1'
                      "
                      >Votación - {{ textoVot }}</v-chip
                    >
                  </v-list-item-action>
                </v-list-item>
                <v-list-item style="justify-content: flex-end">
                  <v-chip outlined><v-icon left>mdi-account-circle-outline</v-icon>Consejeros {{asistentes.length}} de {{ this.consejeros.length }}</v-chip>
                </v-list-item>
              </v-card>
            </v-col>

            <v-tabs
              v-model="tabSelected"
              background-color="primary"
              centered
              dark
            >
              <v-tabs-slider color="secondary"></v-tabs-slider>
              <v-tab href="#tab-citatorio">Citatorio</v-tab>
              <v-tab href="#tab-asistentes">Asistentes</v-tab>
              <v-tab href="#tab-votaciones">Votación</v-tab>
              <v-tab href="#tab-documentos">Documentos</v-tab>
              <v-tab href="#tab-administradores">Administradores</v-tab>
            </v-tabs>

            <abrirCerrarSesionComponent
              v-if="abrirCerrarSesion"
              :mostrar="abrirCerrarSesion"
              :idSesion="idSesion"
              :sesionAbierta="sesion.abierta"
              @cancelar="abrirCerrarSesion = false"
              @sesionAbierta="sesionAbierta"
            />
            <v-tabs-items v-model="tabSelected">
              <v-tab-item value="tab-citatorio">
                <invitacionComponent 
                :fecha="sesion.fecha"
                :consejeros="consejeros"
                />
              </v-tab-item>
              <v-tab-item value="tab-asistentes">
                <asistentesSesionComponent
                  :nombreSesion="sesion.nombre"
                  :idSesion="idSesion"
                  :consejeros="consejeros"
                  :asistentes="asistentes"
                  :asistencias="asistencias"
                  :envioCorreos="sesion.enviarCorreo"
                  @asistenciaAgregada="asistenciaAgregada"
                  @asistenciaModificada="asistenciaModificada"
                  @asistenteEliminado="asistenteEliminado"
                  :permisoEliminarAsistentes="permisoEliminarAsistentes"
                  :permisoEnviarCorreos="permisoEnviarCorreos"
                />
              </v-tab-item>
              <v-tab-item value="tab-votaciones">
                <enlacesVotacionComponent
                  :idSesion="idSesion"
                  :consejeros="consejeros"
                  :votacionEnlaces="votacionEnlaces"
                  @envioCorreos="(e)=>sesion.enviarCorreo=e"
                  @votacionEnlaceAgregado="votacionEnlaceAgregado"
                  @votacionEnlaceEliminado="votacionEnlaceEliminado"
                />
              </v-tab-item>

              <v-tab-item value="tab-documentos">
                <documentosComponent :idSesion="idSesion" />
              </v-tab-item>

              <v-tab-item value="tab-administradores">
                <administradoresComponent
                  :idSesion="idSesion"
                  :administradores="administradores"
                  :dependencias="dependencias"
                  @administradorAgregado="administradorAgregado"
                  @administradorEliminado="administradorEliminado"
                  @dependenciaAsignada="dependenciaAsignada"
                  @dependenciaQuitada="dependenciaQuitada"
                  @permisosModificados="permisosModificados"
                />
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</div>
</template>

<script>
import { mapGetters } from "vuex";
import { getSesionService, getAsistentesService } from "./administrar.service";
import { DateTime } from "luxon";
import sesionMenuComponent from "./sesionMenu.vue";
import abrirCerrarSesionComponent from "./abrirCerrarSesion.vue";
import administradoresComponent from "./administradores/administradores.vue";
import enlacesVotacionComponent from "./votacion/enlacesVotacion.vue";
import documentosComponent from "./documentos/documentos.vue";
import asistentesSesionComponent from "./asistentes/asistentesSesion.vue";
import { ordenarObjetosAsc } from "../../helpers/ordenador";
import invitacionComponent from "./invitacion/invitacion.view.vue"

export default {
  name: "adminSesion",
  components: {
    sesionMenuComponent,
    abrirCerrarSesionComponent,
    administradoresComponent,
    enlacesVotacionComponent,
    documentosComponent,
    asistentesSesionComponent,
    invitacionComponent
  },
  computed: {
    ...mapGetters(["userAdmin", "userId"]),
    porcentajeAsistencia() {
      const porcentaje = Math.round(
        (this.asistentes.length * 100) / this.consejeros.length
      );
      return porcentaje;
    },
    quorum() {
      //50% +1
      let minimo = Math.ceil(this.consejeros.length / 2);
      minimo++;
      return this.asistentes.length >= minimo;
    },
    votacionEconomica() {
      //2/3 del consejo
      let minimo = Math.ceil((this.consejeros.length / 3) * 2);
      return this.asistentes.length >= minimo;
    },
    textoQuorum() {
      let minimo = Math.ceil(this.consejeros.length / 2);
      minimo++;
      return minimo;
    },
    textoVot() {
      let minimo = Math.ceil((this.consejeros.length / 3) * 2);
      return minimo;
    },
    asistentes() {
      let asistentes = [];
      this.asistencias.forEach((asistencia) => {
        const consejero = this.consejeros.find((x) => {
          // console.log(x.usuarioMaster);
          if (x.usuarioMaster && x.usuarioMaster._id == asistencia.usuarioMaster) return true;
          if(x.suplente.usuarioMaster && x.suplente.usuarioMaster._id){
            if (x.suplente.usuarioMaster._id == asistencia.usuarioMaster)
            return true;
          }
        });
        const asistente = this.getAsistente(consejero, asistencia);
        if (asistente) asistentes.push(asistente);
      });

      if (this.votacionEnlaces.length != 0) {
        asistentes.forEach((asistente) => {
          const indexEnlace = this.votacionEnlaces.findIndex(
            (x) => asistente.idConsejero === x.consejero
          );
          if (indexEnlace != -1) {
            asistente.enlaceVotacion = this.votacionEnlaces[indexEnlace].enlace;
            asistente.voto = this.votacionEnlaces[indexEnlace].voto
          }
        });
      }

      asistentes = ordenarObjetosAsc(asistentes, "_id");
      let numero = 1;
      asistentes.forEach((asistente) => {
        asistente.numero = numero++;
      });
 
      return asistentes;
    },

    userAdminSesion(){
      let index = this.administradores.findIndex(x=>x.usuarioMaster==this.userId)
      if(index!=-1) return true;
      return false;
    },
    permisoEliminarAsistentes(){
      let index = this.administradores.findIndex(x=>x.usuarioMaster==this.userId)
      if(index!=-1) {
        if(this.administradores[index].permisoEliminarAsistente) return true;
      }
      return false;
    },
    permisoEnviarCorreos(){
      let index = this.administradores.findIndex(x=>x.usuarioMaster==this.userId)
      if(index!=-1) {
        if(this.administradores[index].permisoEnviarCorreos) return true;
      }
      return false;
    }
  },
  data() {
    return {
      loading: false,
      idSesion: null,
      fechaLocal: "",
      sesion: {},
      tabSelected: 0,
      consejo: {},
      consejeros: [],
      asistencias: [],
      administradores: [],
      dependencias: [],
      abrirCerrarSesion: false,
      votacionEnlaces: [],
      asistentesModificados: [],
      interval: 0,
      loadAsistentes: false,
      texto: null,
    };
  },
  mounted() {
    this.idSesion = this.$route.params.idSesion;
    this.cargarSesion();
    if(!this.loadAsistentes) this.cargarAsistentes();
  },
  methods: {
   
    async cargarSesion() {
      try {
        this.loading = true;
        const serverResponse = await getSesionService(this.idSesion);
        this.loading = false;
        this.$validateResponse(serverResponse);
        if (!serverResponse.ok) {
          throw new Error(serverResponse.mensaje);
        } else {
          this.sesion = serverResponse.sesion;
          this.administradores = serverResponse.administradores;
          this.dependencias = serverResponse.dependencias;
          this.asistencias = serverResponse.asistencias;
          this.votacionEnlaces = serverResponse.votacionEnlaces;

          this.consejo = serverResponse.consejo;
          this.consejeros = serverResponse.consejeros;

          this.setFecha();

        }
      } catch (error) {
        this.loading = false;
        this.$notificarError(error);
      }
    },
    setFecha() {
      let fecha = DateTime.fromISO(this.sesion.fecha);
      fecha.setZone("America/Mexico_City");
      this.fechaLocal = fecha.toLocaleString(DateTime.DATETIME_MED) + " hrs.";
    },
    sesionAbierta(data) {
      this.sesion.abierta = data.sesionAbierta;
      this.abrirCerrarSesion = false;
    },
    asistenciaAgregada(data) {
      this.asistencias.push(data.asistencia);
    },
    getAsistente(consejero, asistencia) {
      if (consejero) {
        let asistente = {
          dependencia: consejero.dependencia,
          divisionDepto: consejero.divisionDepto,
          hora: DateTime.fromISO(asistencia.fecha).toLocaleString(
            DateTime.TIME_24_SIMPLE
          ),
          correoEnviado: asistencia.correoEnviado,
        };
        if (consejero.usuarioMaster._id == asistencia.usuarioMaster) {
          asistente.calidad = "Propietario";
          asistente.idConsejero = consejero._id;
          asistente.categoria = consejero.categoria;
          asistente.funcionario = consejero.usuarioMaster.nombre;
          asistente.codigo = consejero.usuarioMaster.codigo;
          asistente.correoEnviado = asistencia.correoEnviado;
          asistente.correo = consejero.usuarioMaster.correo;
        } else {
          asistente.calidad = "Suplente";
          asistente.idConsejero = consejero._id;
          asistente.categoria = consejero.suplente.categoria;
          asistente.funcionario = consejero.suplente.usuarioMaster.nombre;
          asistente.codigo = consejero.suplente.usuarioMaster.codigo;
          asistente.correoEnviado = asistencia.correoEnviado;
          asistente.correo = consejero.suplente.usuarioMaster.correo;
        }
        return asistente;
      } else {
        this.asistentesModificados.push(asistencia.usuarioMaster);
      }
      return null;
    },
    administradorAgregado(data) {
      this.administradores.push(data.administrador);
    },
    dependenciaAsignada(data) {
      const index = this.administradores.findIndex(
        (x) => x._id == data.idAdministrador
      );
      if (index >= 0)
        this.administradores[index].dependencias.push(data.idDependencia);
    },
    dependenciaQuitada(data) {
      const index = this.administradores.findIndex(
        (x) => x._id == data.idAdministrador
      );
      if (index >= 0) {
        const indexDependencia = this.administradores[
          index
        ].dependencias.findIndex((x) => x == data.idDependencia);
        this.administradores[index].dependencias.splice(indexDependencia, 1);
      }
    },
    permisosModificados(data) {
      const index = this.administradores.findIndex((x) => x._id == data.idAdministrador);

      if (index >= 0){
        this.administradores[index].permisoEnviarCorreos = data.permisoEnviarCorreos;
        this.administradores[index].permisoEliminarAsistentes = data.permisoEliminarAsistentes;
      }
      
    },
    administradorEliminado(data) {
      const index = this.administradores.findIndex(
        (x) => x._id == data.idAdministrador
      );
      if (index >= 0) this.administradores.splice(index, 1);
    },
    votacionEnlaceAgregado(data) {
      const indexEnlace = this.votacionEnlaces.findIndex(
        (x) => x._id == data.enlaceVotacion._id
      );
      if (indexEnlace < 0) this.votacionEnlaces.push(data.enlaceVotacion);
      else
        this.votacionEnlaces[indexEnlace].enlace = data.enlaceVotacion.enlace;
    },
    votacionEnlaceEliminado(data){
      const index = this.votacionEnlaces.findIndex((x) => x._id === data.idVotacionEnlace);
      if(index >= 0) this.votacionEnlaces.splice(index, 1);
    },
    asistenteEliminado(data) {
      const index = this.asistencias.findIndex(
        (x) => x._id == data.idAsistencia
      );

      if (index >= 0) this.asistencias.splice(index, 1);
    },
    asistenciaModificada(data) {
      const index = this.asistencias.findIndex((x) => x._id == data._id);
      this.asistencias[index].correoEnviado = data.correoEnviado;
    },
    cargarAsistentes() {
      this.loadAsistentes = true;
      this.interval = setInterval(async () => {
        if(this.$route.params.idSesion) {
          try {
            const serverResponse = await getAsistentesService(this.idSesion);
            this.$validateResponse(serverResponse);
            if (!serverResponse.ok) {
              throw new Error(serverResponse.mensaje);
            } else {
              this.asistencias = serverResponse.asistencias;
            }
          } catch (error) {
            this.loading = false;
            this.$notificarError(error);
          }
        } else {
          
         clearInterval(this.interval);
        }
     }, 5000);
    },
    regresar(){
      clearInterval(this.interval);
      this.$router.go(-1);
    }
  },
};
</script>