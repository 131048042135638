<template>
  <v-dialog v-model="mostrar" fullscreen persistent>
    <v-card>
      <v-toolbar color="primary" dark dense flat>
      <v-btn small  :loading="loading" @click="$emit('cancelar'), file=null" icon>
       
        <v-icon>mdi-close</v-icon>
        </v-btn>
        <h3>Asignar enlaces de votación mediante CSV</h3>
        
        
      </v-toolbar>
      <v-container>
        <v-card outlined>
          <v-card-title>
            <v-file-input
              label="Seleccione archivo"
              dense
              outlined
              :loading="loadingFile"
              :disabled="loadingFile || loading"
              :error-messages="fileError"
              :hint="inputHint"
              ref="inputFile"
              persistent-hint
              v-model="file"
              @change="archivoSeleccionado()"
            
            >
            </v-file-input>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              small
              :class="{ 'disable-events': !puedeAgregar || loading || enlaces.length != consejerosEnlaces.length }"
              @click="agregarEnlaces()"
              >Agregar enlaces</v-btn
            >
          </v-card-title>
          <v-divider class="mx-3"></v-divider>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">#</th>
                  <th class="text-left">Código</th>
                  <th class="text-left">Dependencia</th>
                  <th class="text-left">Categoría</th>
                  <th class="text-left">Funcionario</th>
                  <th class="text-left">Voto</th>
                  <th class="text-left">Enlace</th>
                  <th class="text-left">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in consejerosEnlaces" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.consejero.codigo }}</td>
                  <td>{{ item.consejero.dependencia }}</td>
                  <td>{{ item.consejero.categoria }}</td>
                  <td>{{ item.consejero.funcionario }}</td>
                  <td>{{ item.voto }} </td>
                  <td>{{ item.enlace }}</td>
                  <td :class="{ 'error-text': item.error }">
                    <v-icon v-if="item.error" color="red">mdi-close</v-icon>
                    <!-- <v-icon v-else color="green darken-2">mdi-check</v-icon> -->
                    {{ item.status }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";

import { asignarEnlaceVotacionService, subirCSV } from "../administrar.service";

export default {
  name: "asignarEnlaceCSV",
  props: {
    mostrar: Boolean,
    consejeros: Array,
    idSesion: String,
  },
  mixins: [validationMixin],
  computed: {
    ...mapGetters(["userAdmin"]),
  },
  data() {
    return {
      loading: false,
      loadingFile: false,
      fileError: null,
      puedeAgregar: false,
      inputHint: "",
      enlaces: [],
      consejerosEnlaces: [],
      file:null
    };
  },
  methods: {
    async archivoSeleccionado() {
      if (this.file) {
        this.fileError = null;
        try {
          let listaVotacion = this.consejeros.map( x => { return x.codigo } )
          if (this.file.type !== "text/csv")
            throw new Error("No se seleccionó un archivo CSV");
          const archivo = new FormData();
          archivo.append("file", this.file);
          archivo.append("listaVotacion",listaVotacion)

          this.loadingFile = true;
          const serverResponse = await subirCSV(archivo);
          this.loadingFile = false;
          
          if (!serverResponse.ok) {
           this.file=null
            this.fileError = serverResponse.mensaje;
            }
          else {
            this.enlaces = serverResponse.enlaces.map((x) => { return {enlace:x.enlace, voto:x.voto} });
            this.puedeAgregar = true;
            this.consejerosEnlaces = []
            this.prepararEnlaces();
            this.inputHint = `Registros: ${this.enlaces.length}`;
          }
        } catch (error) {
          this.loadingFile = false;
          this.fileError = error.message;
        }
      }
    },
    prepararEnlaces() {
      this.consejeros.forEach((consejero, index) => {
        this.consejerosEnlaces.push({
          consejero,
          enlace: this.enlaces[index].enlace || null,
          voto:this.enlaces[index].voto || null,
          status: null,
        });
      });
    },
    async agregarEnlaces() {
      for (const item of this.consejerosEnlaces) {
        this.inputHint = `Procesando...`;

        this.loading = true;
        const sendData = {
          idSesion: this.idSesion,
          idConsejero: item.consejero._id,
          enlace: item.enlace,
          voto:item.voto
        };
        try {
          const serverResponse = await asignarEnlaceVotacionService(sendData);
          if (serverResponse.ok) {
            item.status = "Agregado correctamente";
           const emitData = { enlaceVotacion: serverResponse.enlaceVotacion };
           this.$emit("votacionEnlaceAgregado", emitData);
          } else {
            item.status = serverResponse.mensaje;
            item.error = true;
          }
          this.loading = false;
        } catch (error) {
          this.item.status = error.message;
          this.item.error = true;
          this.loading = false;
        }
      }
      this.puedeAgregar=false;
      this.inputHint = `Registros: ${this.enlaces.length}`;
      this.$emit('cancelar')
    },
  },
};
</script>

<style>
.error-text {
  color: brown;
}
</style>